.agreement-step-tabs-absolute {
  top: -0.5vw;
  z-index: 99;
}

.cooperation-billing-table th:nth-child(1),
.cooperation-billing-table th:nth-child(2),
.cooperation-billing-table th:nth-child(3),
.cooperation-billing-table th:last-child {
  width: 16%;
}

.cooperation-billing-table th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:last-child) {
  width: 10%;
}

.agreement-profile .autoCompleteInput {
  border: 0 !important;
  margin-bottom: 0 !important;
}

.employee-type label,
.employee-type .height-20,
.coeffDataDynamic label,
.coeffDataDynamic .height-20 {
  display: none;
}

td.employee-type,
td.coeffDataDynamic,
td.agreement-profile {
  position: relative;
  padding: 0 !important;
}

.cooperation-billing-table .field-shadow {
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.coeffDataDynamic .col-md-12 {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.coeffDataDynamic .col-md-12 .form-control {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}

/* .coeffDataDynamic .col-md-12 .form-control:focus
.employee-type .multiSelectDropdown__control--is-focused,
.agreement-profile .autoCompleteInput:focus
 {
  border: 0.052vw solid var(--color-dark-purple) !important;
  border-radius: 0vw !important;
} */

.agreement-actions .calender-icon svg {
  width: 2vw !important;
  height: 2vw !important;
}

.calenderNoInputPosition {
  position: absolute;
  right: 0 !important;
  top: -0.24vw !important;
}

.euroSignAgreement {
  align-items: center;
  background-color: var(--circlePrimaryColor);
  display: flex;
  justify-content: center;
  width: 2vw;
  margin-left: 0vw !important;
}

.agreement-wage-elements.field-shadow{
  border-radius: 0.5vw !important;
}

.agreement-wage-elements .form-control:disabled {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remarksTextArea .height-20 {
  display: none;
}

.agreement-billing-tab thead::after {
  display: none;
}

/* Cooperation agreement design */
.manageAgreementClass th:nth-child(1) {
  width: 8vw;
  max-width: 8vw;
}

.manageAgreementClass th:nth-child(2) {
  width: 15%;
}

.manageAgreementClass th:nth-child(3) {
  width: 20%;
}

.manageAgreementClass th:nth-child(4) {
  width: 13%;
}

.manageAgreementClass th:nth-child(5) {
  width: 15%;
}

.manageAgreementClass th:nth-child(6) {
  width: 10%;
}


.tableContainerAgreement {
  height: calc(100vh - 17vw);
  overflow: auto;
}

.general-agreement-table th:nth-child(1) {
  width: 35%;
}

.general-agreement-table th:nth-child(2) {
  width: 50%;
}