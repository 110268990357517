.welcome-message {
  color: var(--color-dark-purple);
  font-size: var(--size-50px);
  font-family: var(--font-roobert-semibold);
  line-height: var(--size-60px);
}

.welcome-text {
  font-size: var(--size-50px);
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-semibold);
  line-height: var(--size-60px);
}

.welcome-message-homepage {
  color: var(--color-dark-purple);
  font-size: var(--size-50px);
  font-family: var(--font-roobert-regular);
  line-height: var(--size-60px);
}

.name-text {
  font-family: var(--font-fraunces-semibold-italic);
  font-size: var(--size-50px);
  line-height: var(--size-60px);
}

.dashboardWrapper .recruiter-action-tabs {
  margin-top: 1vw;
}

.p-tabview .p-tabview-nav {
  border: 0vw !important;
}

.p-tabview .p-tabview-panels {
  display: none;
}

.p-tabview-nav-btn svg {
  border: 0.052vw solid var(--color-dark-pink);
  border-radius: 50%;
  height: 1.302vw;
  padding: 0.26vw;
  width: 1.302vw;
  color: var(--color-dark-pink);
  transition: all 0.5s;
}
.p-tabview .p-tabview-nav-btn.p-link {
  width: 2vw !important;
}
.p-tabview-nav-btn svg:hover {
  background-color: var(--color-dark-pink);
  color: var(--color-white);
}

.p-tabview .p-tabview-nav {
  padding-left: 0vw !important;
  margin-bottom: 0vw !important;
}

.p-unselectable-text .card:hover {
  background-color: var(--color-dark-pink);
  border: 0.052vw solid var(--color-dark-pink);
  transition: all 0.5s;
  color: var(--color-white);
  text-decoration: none !important;
}

.p-unselectable-text .recruitment-card-title {
  font-size: var(--size-14px);
}

.p-unselectable-text .recruitment-card-num {
  font-size: var(--size-16px);
}

.p-unselectable-text {
  margin-right: 0.2vw !important;
  min-width: 10vw !important;
}

.nameTitle {
  padding: 0 0 0.3vw 0;
  position: sticky;
  top: 0;
  z-index: 10;
  font-size: var(--size-16px);
  font-family: var(--font-roobert-semibold);
}

/* Budget */

.circular-progress-bar-custom {
  width: 9.375vw;
  margin-left: auto;
  margin-right: 8.333vw;
  box-shadow: 0vw 0vw 0.208vw 0.26vw rgb(229 84 150 / 20%);
  border-radius: 50% !important;
  height: 9.375vw;
  position: relative;
}

.CircularProgressbar {
  box-shadow: 0vw 0vw 0.885vw 0.521vw rgb(229 84 150) inset;
  border-radius: 50%;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--circlePrimaryColor) !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryBrandColor) !important;
  stroke-linecap: square !important;
}

.CircularProgressbar-text {
  fill: var(--color-dark-purple) !important;
  font-size: var(--size-14px) !important;
  font-family: var(--font-roobert-semibold);
}

.circular-progress-bar-custom::after {
  content: attr(data-label);
  position: absolute;
  right: -55%;
  top: 45%;
  width: 35%;
}

.achieved-text,
.circular-progress-bar-custom::after {
  font-size: var(--size-14px);
  color: var(--color-dark-purple);
}

.circular-progress-bar-custom::before {
  content: '';
  position: absolute;
  width: 1.302vw;
  height: 0.26vw;
  background: var(--primaryBrandColor);
  right: -14%;
  top: 50%;
}

.my-budget-title {
  font-size: var(--size-25px);
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-semibold);
}

.budgetWrapper > .form-control {
  max-height: 2.344vw !important;
}

.budgetWrapper label {
  display: none;
}

.new-vacancies-cadidates-card {
  padding: 1vw;
  border-radius: 0.4vw !important;
  background-color: var(--circlePrimaryColor) !important;
}

.new-vacancies-cadidates a:hover .new-vacancies-cadidates-card {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white);
}

.new-vacancies-cadidates a {
  text-decoration: none !important;
}

a .recruitment-card-title {
  font-size: var(--size-18px);
}

.recruitment-card-num,
a .recruitment-card-num {
  font-family: var(--font-roobert-bold);
  font-size: var(--size-25px);
}

.border--bottom-tabs {
  border-bottom: 2px solid var(--header-bg-table-color);
}

.dashboard-tasks-meeting-tabs .nav-link.active,
.dashboard-tasks-meeting-tabs .nav-link:hover {
  border-bottom: 2px solid var(--color-dark-pink) !important;
  color: var(--color-dark-pink) !important;
}
.dashboard-tasks-meeting-tabs .nav-link {
  border-bottom: 2px solid transparent !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: var(--color-dark-purple);
  position: relative;
  top: 2px;
}

.dashboard-tasks-meeting-tabs .nav-link {
  margin-left: 2vw;
}

.dashboard-work-table {
  height: 90%;
}

.tab-pane {
  height: 100%;
}

.tasks-meeting-section .tab-content {
  height: 100%;
  position: relative;
}

.link-btn {
  background-color: var(--circlePrimaryColor) !important;
  border: 0.052vw solid var(--circlePrimaryColor) !important;
  color: var(--color-dark-pink) !important;
  font-size: var(--size-14px) !important;
  padding: 0.5vw !important;
  border-radius: 0.5vw;
  transition: all 0.35s ease;
}

.link-btn:hover {
  background-color: var(--color-dark-pink) !important;
  color: var(--circlePrimaryColor) !important;
  text-decoration: none !important;
}

.dashboard-tab-links {
  min-height: 5.5vw;
  text-align: center;
}