.budgetYearPicker .rmdp-container {
  width: 100%;
}

td.regionName {
  font-family: var(--font-roobert-semibold);
  font-size: var(--size-18px) !important;
  padding-top: 1vw !important;
  padding-bottom:  1vw !important;
}


.budgetTable tbody td, .budgetTable thead th {
  max-width: 2vw!important;
  min-width: 2vw!important;
  overflow: hidden;
  position: relative;
}

.dailyTurnOverTable th {
  width: 20%;
}

.dailyTurnOverTable td .form-control:focus,
.targetAndBudgetOverviewTable td input:focus {
  border: 0.052vw solid var(--color-dark-purple) !important;
  outline:  0 !important;
}

.targetAndBudgetOverviewTable td .height-20,
.targetConfigWrapper td .height-20,
.targetConfigWrapper td label {
  display: none;
}

.targetAndBudgetOverviewTable td input {
  color: var(--color-dark-purple) !important;
}


.targetConfigWrapper td {
  padding: 0 !important;
}

.targetConfigWrapper .targetPer * {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.targetConfigWrapper .targetPer .multiSelectDropdown__control--is-focused,
.targetConfigWrapper td .multiSelectDropdown__control--is-focused,
.targetBudgetWrapper td input.dataField:focus {
  border: 0.052vw solid var(--color-dark-purple) !important;
}


.dailyTurnOverTable td {
  padding: 0.5vw !important;
}