.react-datepicker-wrapper {
  width: 100%;
}

.calender-icon {
  top: 2vw !important;
  right: 0.521vw;
}

.calender-icon svg {
  color: var(--color-dark-pink);
  width: 1.3vw;
  height: 1.3vw;
}

/* static/css/calender.css */
.custom-datepicker-popper {
  z-index: 2000 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  overflow: visible;
}

.filter-group:not(:last-child) {
  margin-right: 0.5vw !important;
}

.tableSearchFields .calendarWithoutLabel .react-datepicker__input-container input {
  width: 100%;
  padding: 0.25vw !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableSearchFields .date-selected input {
  width: 2.8vw !important;
  box-shadow: none !important;
}

.tableSearchFields .date-selected input:focus {
  border: 0.052vw solid transparent !important;
}

.tableSearchFields .date-selected .react-datepicker__input-container {
  border-radius: 0.4vw !important;
}