.remunerationInputGroup .input-group-text {
    background-color: var(--circlePrimaryColor) !important;
}

.contractBillingInfo svg {
    width: 1vw;
    height: 1vw;
    color: var(--color-dark-pink);
}

.remuneraionPayroll .tab-title .table-action-icons svg {
    width: 30px !important;
    height: 30px !important;
}

.contractPerfCodeModal .modal-content {
  width: 50vw;
  margin: auto;
}

.deviatingCoefficientTable  thead {
  position: sticky;
  top: 0;
  background-color: transparent;
}

.deviatingCoefficientTable {
  max-height: 20vw;
  overflow: auto;
  min-height: 4vw;
}

.progressiveReturnToWork >div >label {
  display: none;
}