.form-button:hover {
  text-decoration: none !important;
}

.matchingBtn {
  min-width: 10vw;
}

.form-height-matching-list {
  min-height: calc(100vh - 35vw);
}

.matchingModalPopUP .modal-dialog {
  width: 200vw !important;
}

.matchingModalPopUpBody {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-height: calc(100vh - 14vw);
  overflow-y: auto;
  padding-right: 1.5vw !important;
}

.matchingModalPopUP.modal.show .modal-dialog {
  min-width: 85vw !important;
  max-width: 85vw !important;
  width: 85vw !important;
}

.matchingModalPopUPGrid {
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
}

.matchingModalPopUPGridSection3 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1vw;
}

.matchingModalPopUPGridlabel {
  font-family: var(--font-roobert-semibold);
  width: 35%;
}

.matchingDetailVacancyPopup .matchingModalPopUPGridContent {
  width: 50% !important;
}

.matchingModalPopUPGridContent {
  font-family: var(--font-roobert-regular);
  width: 65%;
}

.matchingModalPopUPGrid ul {
  padding-left: 2vw !important;
}

.martchingCandidateScoreLabel {
  border-bottom: 0.052vw solid var(--color-dark-purple);
  padding-bottom: 0.15vw;
  color: var(--color-dark-pink);
}

.matchingPopUpProfileImage {
  width: 100%;
  height: 8.5vw;
}

.matchingPopupFollowUpHeight {
  padding-top: 0.45vw !important;
  max-height: calc(100vh - 20vw);
  overflow: auto;
}

.matchingPopupFollowUpHeight .card {
  margin-left: 0.25vw !important;
  margin-right: 0.5vw !important;
}

.matchingModalPopUPGrid .addFollowUp {
  margin-bottom: 0.5vw !important;
}

.matchingScrollWrapper {
  height: 50vh;
  overflow-y: auto;
  padding-right: 0.5vw;
}

.matchingScrollWrapper .primeSelect {
  display: flex;
  align-items: center;
  padding: 0.25vw !important;
}

.matchingScrollWrapper .accordion-body {
  padding: 0.5vw !important;
}