/* Header */
.page-header {
  background-color: var(--color-white);
  height: 3.5vw;
  top: 0;
  z-index: 1500;
}

.this-week-section .dropdown-toggle {
  border: 0.052vw solid var(--circlePrimaryColor) !important;
}

.this-week-link {
  background-color: var(--circlePrimaryColor) !important;
  height: 2.344vw;
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px) !important;
}

.this-week-section {
  margin-right: 3.5vw;
  margin-left: 3vw;
}

.dashboardCurrentCalendarIcon {
  background-color: var(--circlePrimaryColor);
  border: 0.052vw solid var(--circlePrimaryColor);
  border-radius: 0.417vw;
  box-shadow: none;
  color: var(--color-dark-pink);
  height: 2.344vw;
  position: absolute;
  top: 0;
  width: 2.344vw;
}

.dashboardCurrentCalendarIcon span {
  font-size: var(--size-18px) !important;
}

.monthPrevIcon {
  left: -2.604vw;
}

.monthNextIcon {
  right: -2.604vw;
}

/* Language dropdown */

#lang-select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../images/LandDropdown.svg');
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  height: 2.344vw !important;
  text-transform: uppercase;
  width: 4vw;
  font-size: var(--size-18px);
  padding: 0.3vw 0.3vw 0.3vw 0.5vw;
}

#lang-select:focus-visible {
  outline: 0.052vw solid var(--color-dark-pink) !important;
  border: 0.052vw solid var(--shadowColor) !important;
}

.lang-options {
  border-radius: 0.5vw;
}

/* Profile circle */
.name-circle {
  background-color: var(--primaryBrandColor);
  border-radius: 50%;
  height: 3.125vw;
  width: 3.125vw;
  font-size: var(--size-18px);
}

.header-profile-pic .dropdown-menu {
  left: -5.083vw !important;
  width: 8.5vw !important;
  top: 0 !important;
  transform: translate(-1.198vw, 3.49vw) !important;
  overflow-wrap: break-word;
  min-width: 8.5vw !important;
  max-width: 8.5vw !important;
}

.header-profile-pic .dropdown-toggle::after {
  display: none;
}

.profile-dropdown-links,
#memoryTooltip .tooltip-inner {
  border: 0.052vw solid #0000 !important;
  box-shadow: 0 0.104vw 0.417vw 0 rgba(229, 0, 125, 0.502);
  color: var(--color-dark-purple) !important;
  border-radius: 0.5vw !important;
  padding: 0.5vw !important;
  background-color: var(--color-white);
}

.profile-dropdown-links .dropdown-item {
  padding: 0.25vw !important;
}

.profile-dropdown-links a {
  color: var(--color-dark-purple) !important;
  font-family: var(--font-roobert-regular);
  font-size: var(--size-18px);
  white-space: break-spaces;
}

.profile-dropdown-links a:hover,
.profile-dropdown-links a:active {
  color: var(--color-dark-pink) !important;
  text-decoration: none !important;
  background-color: var(--circlePrimaryColor) !important;
}

.header-sticky {
  background-color: var(--color-white);
  height: 2.5vw;
  top: 3.5vw;
  z-index: 1001;
}

.page-title,
.subform-title .page-title {
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-bold);
  font-size: var(--size-25px);
}


.my-profile-picture img {
  width: 7vw !important;
  height: 7vw !important;
}

.page-header .p-autocomplete-loader {
  top: 30% !important;
  right: 1vw !important;
  width: 1vw;
  height: 1vw;
}