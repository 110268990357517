.manageInflationTable th {
  width: 20%;
}

.form-height-inflation {
  min-height: calc(100vh - 15vw);
}

.inflationData {
  font-family: var(--font-roobert-semibold);
}

.addInflationTable td:last-child {
  padding: 0 !important;
  position: relative;
}

.addInflationTable td:last-child .col-md-12 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.addInflationTable td:last-child .col-md-12 input {
 font-family: var(--font-roobert-regular) !important;
}

.addInflationTable td:last-child .col-md-12 input:focus {
  outline: 0 !important;
  border: 0.052vw solid var(--color-dark-purple) !important;
}