.signature-popup .modal-footer {
  display: none;
}

.signatureCanvas{
  width: 100% !important;
  height: 15.625vw !important;
}

.signature-popup .modal-header{
  border: 0px !important;
  padding-bottom: 0px !important;
}

.signature-section{
  width: 20.833vw;
  height: 10.417vw;
  object-fit: contain;
}