.admin-fiche-step-tabs-absolute {
  top: -0.5vw;
  z-index: 99;
}

.adminFicheSuffixTextOvertime .input-group-text {
  height: 2.25vw !important;
  right: 0.05vw;
  top: 0.05vw;
  background-color: var(--header-bg-table-color);
  min-width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
  font-size: var(--size-16px);
}

.adminFicheSuffixTextOvertimeLabel label,
.wagePreminumApplicabilityTable .height-20 {
  display: none;
}

.adminFicheSuffixText .input-group-text {
  top: 1.75vw;
}

.kbWageSuppliment {
  top: -0.6vw;
}

.weeklyPlanning .TableHeader th,
.weeklyPlanningData {
  background-color: #f5e9ef !important;
}

table td.weeklyPlanningData {
  padding-left: 0.5vw !important;

}
.activeWeeklyPlanningData td {
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
  font-family: var(--font-roobert-semibold);
  background-color: var(--shadowColor);}

.weeklyWorkingHours .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: var(--color-dark-pink) !important;
}

.weeklyWorkingHours .nav-pills .nav-link {
  color: var(--color-dark-purple);
}

.wagePreminumApplicabilityTable th:nth-child(1) {
  width: 7%;
}
.wagePreminumApplicabilityTable th:nth-child(2) {
  width: 15%;
}

.wagePreminumApplicabilityTable th:nth-child(5) {
  width: 10%;
}

.wagePreminumApplicabilityTable th:nth-child(3),
.wagePreminumApplicabilityTable th:nth-child(4),
.wagePreminumApplicabilityTable th:nth-child(6),
.wagePreminumApplicabilityTable th:nth-child(7) {
  width: 15%;
}

.wagePremiumActions {
  margin-top: 7px;
}

.wagePreminumApplicabilityTable input[type="text"]:focus {
  border: 1px solid var(--color-dark-purple) !important;
}

.weeklyPlanning td input[type="text"]:focus {
  border: 0.052vw solid var(--color-dark-purple) !important;
  outline: 0 !important;
}

.coomercialProfileAccordion .d-grid {
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.generalOther .height-20{
  display: none;
}

.weeklyWorkingHoursTable table thead {
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 99;
}

.weeklyPlanningTable td {
  padding: 0.5vw 0 !important;
} 