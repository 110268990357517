.addCityProfile label,
.addCityProfile .height-20,
.workTypeTable .workTypeFactor label,
.workTypeTable .workTypeFactor .height-20,
.wageElementsClass label,
.wageElementsClass .height-20 {
  display: none;
}

.add-city-height {
  min-height: calc(100vh - 15vw);
}

.addCityProfile
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled),
.wage-codes
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled) {
  max-height: 5vw;
  overflow: auto;
}

.addCityProfile
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled)
  .multiSelectDropdown__indicators,
.wage-codes
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled)
  .multiSelectDropdown__indicators {
  align-items: start !important;
  position: sticky;
  top: 0;
  height: 100%;
}

.addCityProfile
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled)
  .multiSelectDropdown__dropdown-indicator,
.wage-codes
  .multiSelectDropdown__control:not(.multiSelectDropdown__control--is-disabled)
  .multiSelectDropdown__dropdown-indicator {
  position: relative;
  top: 0.6vw;
}

.wageElementsAmount .input-group-text {
  background-color: var(--header-bg-table-color);
  font-size: var(--size-16px);
  width: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.wageElementsAmount .inclusiveAmount {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.wage-codes .multiSelectDropdown__multi-value__label {
  white-space: break-spaces;
}