/* Overlay */
.fullscreen-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.26vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.fullscreen-loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.3);
  z-index: -1;
}

.page-width {
  width: calc(100vw - 6vw);
  margin-left: 5.6vw;
  transition: margin-left 1s ease;
}

.main-menu.expanded+.page-width {
  margin-left: 14.5vw !important;
}


.body-height {
  margin-right: 0 !important;
  min-height: calc(100vh - 3.5vw);
}

.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.equal-cols>[class*="col"] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.equal-cols>[class*="col"]>* {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}


.search-bar {
  position: sticky;
  z-index: 998;
  background-color: var(--color-white);
  top: 6vw;
}

/* Form border */

.form-border {
  background-color: var(--budget-bg);
  border-radius: 1vw;
  padding: 2vw;
}

.disabled {
  opacity: 0.9;
  pointer-events: none;
  /* Prevent interactions */
}

/* Pagination */

.pagination {
  padding-top: 0.1vw;
}

.pagination .page-num {
  min-width: 2.5vw;
  color: var(--color-dark-purple);
  font-size: 1vw !important;
}

.pagination .previous-btn.disabled,
.pagination .next-btn.disabled {
  background-color: var(--color-dark-purple) !important;
}

.pagination .btn svg path {
  fill: var(--color-white) !important;
}

.pagination .btn.active,
.pagination .previous-btn,
.pagination .next-btn {
  background-color: var(--color-dark-pink) !important;
  color: var(--color-white) !important;
  border-radius: 50% !important;
  width: 2.083vw;
  height: 2.083vw;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-active,
.pagination .page-num:hover {
  color: var(--color-dark-pink);
}

.pagination svg {
  width: 1vw !important;
  height: 1vw !important;
}

/* Create Tabs sticky */
.createTabsSticky {
  background-color: var(--color-white);
  top: 5.958vw;
  transition: all 0.5s ease-in-out;
  z-index: 997;
}

.createFormTabs .nav-link {
  background-color: var(--shadowColor);
  border: 0.052vw solid var(--shadowColor) !important;
  box-shadow: 0 0.104vw 0.104vw 0 #63636333;
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
  flex-basis: 0;
  flex-grow: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 0.5vw !important;
  padding: 0.5vw 0.25vw !important;
}

.createFormTabs .nav-link.active,
.createFormTabs .nav-link:hover {
  background-color: var(--color-dark-pink) !important;
  color: var(--color-white) !important;
  border: 0.052vw solid var(--color-dark-pink) !important;
}

.createFormTabs .nav-link.error {
  background-color: var(--color-white) !important;
  border: 0.208vw solid var(--color-red) !important;
  color: var(--color-red) !important;
}

.createFormTabs {
  padding: 1.25vw;
}

.tab-title {
  color: var(--color-dark-purple);
  font-size: var(--size-22px);
  padding-top: 0.677vw;
  padding-bottom: 1.302vw;
  font-family: var(--font-roobert-semibold);
}

.tab-subtitle {
  color: var(--color-dark-purple);
  font-size: var(--size-22px);
  font-family: var(--font-roobert-bold);
  padding-bottom: 1.302vw;
}

.internalContentWrapper {
  border: 0.052vw solid #d8d8d8;
  border-radius: 0.5vw;
  padding: 1vw;
}

/* Page not found and access denied */
.mainWrapper {
  min-height: inherit;
}

.access-denied-code {
  color: var(--color-dark-pink);
  font-family: var(--font-fraunces-semibold-italic);
  font-size: var(--size-160px);
  line-height: 13vh;
}

.access-denied-title {
  font-family: var(--font-roobert-regular);
  font-size: var(--size-60px);
}

.access-denied-content {
  font-size: var(--size-32px);
}

.error-border,
.weeklyPlanning .error-border,
.weeklyPlanning .error-border:focus {
  border: 0.107vw solid var(--color-red) !important;
}

.loginDetailsCard {
  background-color: var(--profileBgColor) !important;
  font-size: var(--size-18px);
  padding: 1.5vw;
}

.loginImageWrapper svg {
  height: 1.5vw;
  width: 1.5vw;
}

.loginImageWrapper svg path {
  fill: var(--color-dark-pink);
}

.screening-checkbox p {
  margin-bottom: 0 !important;
}

.numberWrapper {
  width: 2vw;
  height: 2vw;
  background-color: var(--circlePrimaryColor);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5vw;
  color: var(--color-dark-pink);
}

.numberWrapper:hover {
  background-color: var(--color-dark-pink);
  color: var(--circlePrimaryColor);
}

.searchFields label,
.searchFields .height-20 {
  display: none;
}

.advSearchCloseIcon {
  background-color: var(--color-white);
  color: var(--color-dark-pink);
  border: 1px solid var(--color-dark-pink);
  min-height: 2.344vw;
  padding: 0.5vw !important;
  font-size: var(--size-16px) !important;
  border-radius: 0.4vw;
  transition: background-color 0.3s ease;
}

.advSearchCloseIcon:hover {
  background-color: var(--color-dark-pink);
  color: var(--color-white);
}

.advIcon svg {
  width: 1.8vw !important;
  height: 1.8vw !important;
}

.tableSection .advIcon {
  padding: 0 !important;
}

.table-action-icons .showLessIcon svg {
  background-color: var(--budget-bg) !important;
  color: var(--color-dark-purple) !important;
}

.showLessIcon svg:hover {
  background-color: var(--color-dark-purple) !important;
  color: var(--color-white) !important;
}

.advClose svg {
  width: 1.7vw !important;
  height: auto !important;
}

.advClose {
  padding: 0.2vw 0.7vw !important;
}

.headerIcons .infoIcon svg {
  width: 1.6vw !important;
  height: 1.6vw !important;
}

.headerIcons .infoIcon {
  padding: 0.65vw !important;
  margin-right: 0 !important;
}

.manageInfoIcon .infoHelpText {
  display: none;
}

.manageInfoIcon:hover .infoHelpText {
  display: block;
  position: absolute;
  left: -18.2vw;
  width: 18vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  background-color: var(--color-dark-purple);
  color: var(--color-white);
  padding: 0.5vw;
  border-radius: 0.5vw;
  font-size: var(--size-16px);
}

.manageInfoIcon:hover .infoHelpText::after {
  content: "";
  position: absolute;
  right: -0.5vw;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 0.817vw solid var(--color-dark-purple);
  border-top: 0.56vw solid transparent;
  border-bottom: 0.56vw solid transparent;
  transform: translateY(-50%);
}

.headerIcons .manageInfoIcon:hover .infoHelpText {
  top: 115% !important;
  transform: translateY(0%);
  right: 0 !important;
  left: auto !important;
}

.headerIcons .manageInfoIcon:hover .infoHelpText::after {
  content: "";
  position: absolute;
  right: -0.5vw;
  top: -10%;
  right: 5%;
  width: 0;
  height: 0;
  border-left: 0.817vw solid var(--color-dark-purple);
  border-top: 0.56vw solid transparent;
  border-bottom: 0.56vw solid transparent;
  transform: rotate(-90deg);
}


.hideLabel label,
.hideLabel .height-20 {
  display: none;
}

.agreement-wage-elements>* {
  width: 100%;
}


.Toastify__toast-container {
  width: 18vw !important;
  padding: 0.5vw !important;
}

.Toastify__toast-body {
  padding: 0.5vw !important;
}

.Toastify__toast-icon {
  width: 1vw !important;
  height: 1vw !important;
  margin-right: 0.5vw !important;
}


.Toastify__close-button svg {
  width: 1vw !important;
  height: 1vw !important;
  align-self: center;
}

.Toastify__close-button--light {
  padding: 0.5vw !important;
}

@media screen and (min-width:3000px) {
  .row>* {
    padding-left: 0.5vw !important;
    padding-right: 0.5vw !important;
  }
}

.hide-height .height-20 {
  display: none;
}

.headerGlobalSearch .p-fluid {
  width: 3vw !important;
  transition: width 0.6s ease;
  /* Only animate the width for smooth transition */
}

.headerGlobalSearch .p-fluid .p-inputwrapper input {
  box-shadow: none !important;
}

.globalSearchIcon {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 0.2vw;
}

.headerGlobalSearchVisible .p-fluid {
  width: 15vw !important;
  transition: width 0.6s ease;
  /* Only animate the width for smooth transition */

}

.headerGlobalSearchVisible .p-fluid .p-inputwrapper input {
  background-color: var(--circlePrimaryColor) !important;
}

.p-autocomplete-item:has(.headerAutocompleteItem):hover {
  background: var(--circlePrimaryColor) !important;
  color: var(--color-dark-pink) !important;
}

.p-autocomplete-item:has(.headerAutocompleteItem) {
  min-height: fit-content !important;
  white-space: normal !important;
}

.headerGlobalSearchVisible .autoCompleteInput {
  color: var(--color-dark-pink) !important;
  font-family: var(--font-roobert-semibold) !important;
  text-align: left;
}

.headerGlobalSearch .p-disabled {
  background-color: var(--color-white) !important;
}
.addressPopup {
  z-index: 1500;
}

.p-autocomplete-item:has(.itemDisabled) {
  background-color: var(--header-bg-table-color); /* Replace with the desired background color */
}

.p-autocomplete-item {
  height: auto !important;
}