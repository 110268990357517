.myTodosTable th {
  width: 20%;
}

.euroWrapper .euroSign {
  top: 1.71vw;
  width: 2vw;
  height: 2.344vw;
  background-color: var(--header-bg-table-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
