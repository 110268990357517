.todoTimePicker select {
  border: 0.052vw solid transparent !important;
  width: 100%;
}

.todoTimePicker select:focus {
  border: 0.052vw solid var(--shadowColor) !important;
}

.todoTimePicker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: end;
  /* Aligns items horizontally within each cell */
}

.todoTimePicker .hourWrapper,
.todoTimePicker .minuteWrapper {
  width: 45%;
}

.todoTimePicker {
  grid-gap: 1vw;
  display: grid;
  gap: 1.5vw;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
}

.todoTimePicker .colonWrapper {
  text-align: center;
  width: 10%;
}

.followUpWrapper {
  max-height: calc(100vh - 20vw);
  overflow: auto;
  padding-right: 1vw !important;
}

.successionPopup {
  background: rgba(255, 255, 255, 0.8);
}

.successionPopup.modal.show .modal-dialog {
  min-width: 90vw !important;
  max-width: 90vw !important;
  width: 100vw !important;
}

.manageFollowUpModal .modal-content {
  max-width: 40vw;
  margin: auto;
}


.OMRecriterTableSticky {
  top: 9.167vw;
}

.manageFollowUpWrapper {
  min-height: calc(100vh - 230px);
}

.successionPopup .modal-dialog {
  max-width: 100%;
}

@media screen and (min-width:1800px) {
  .successionPopup .modal-dialog .modal-content {
    width: 75%;
    margin: auto;
  }
}


@media screen and (min-width:1600px) and (max-width:1799px) {
  .successionPopup .modal-dialog .modal-content {
    width: 80%;
    margin: auto;
  }
}


@media screen and (min-width:1400px) and (max-width:1599px) {
  .successionPopup .modal-dialog .modal-content {
    width: 85%;
    margin: auto;
  }
}



@media screen and (min-width:1200px) and (max-width:1399px) {
  .successionPopup .modal-dialog .modal-content {
    width: 90%;
    margin: auto;
  }
}

.followUpTime .hourWrapper select,
.followUpTime .minuteWrapper select {
  width: 100%;
  padding-right: 0 !important;
}

/* Manage follow up */
.manageFollowup th:nth-child(1) {
  width: 5vw;
  max-width: 5vw;
}

.manageFollowup th:nth-child(2) {
  width: 10%;
}

.manageFollowup th:nth-child(3) {
  width: 9%;
}

.manageFollowup th:nth-child(4) {
  width: 7vw;
  max-width: 7vw;
}

.manageFollowup th:nth-child(5) {
  width: 5%;
}


.manageFollowup th:nth-child(6),
.manageFollowup th:nth-child(7) {
  width: 11%;
}

.manageFollowup th:nth-child(8),
.manageFollowup th:nth-child(9),
.manageFollowup th:nth-child(10) {
  width: 10%;
}

.followUpTimeSection .hourWrapper,
.followUpTimeSection .minuteWrapper {
  width: 45%;
}

.followUpTimeSection .hourWrapper>*,
.followUpTimeSection .minuteWrapper>* {
  width: 100%;
}