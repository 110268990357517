.available-text {
  bottom: 2vw;
  font-size: var(--size-20px);
  position: relative;
  transform: rotate(-24deg);
}

.candidate-cv-download,
.candidate-cv-upload,
.matchingCandidateBtn {
  margin-left: 6vw !important;
  margin-top: 0.5vw !important;
}

.modal .fileUploadWrapper button svg {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.profileCardImage {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.candidate-step-tabs-absolute {
  top: -2.344vw;
  z-index: 997;
}

.proficiency-level .height-20,
.candidateSearchSection .height-20,
.proficiency-level label {
  display: none;
}

.textAppendBg {
  display: flex;
  align-items: center;
  padding: 0 0.8vw;
  background-color: var(--header-bg-table-color) !important;
  border-radius: 0.5vw;
  right: -0.1vw;
  font-size: var(--size-18px);
}

.internalInfoModal .modal-dialog {
  min-width: 40vw;
  max-width: 41vw;
  margin: auto;
}

.competencesName {
  font-size: var(--size-16px) !important;
}

.competencesAccordion .accordion-body .float-end svg {
  width: 1.3vw;
  height: 1.3vw;
}

.candidate-popup-submit div:not(:last-child) a {
  text-decoration: none !important;
}

.candidate-popup-submit a:hover {
  text-decoration-color: var(--color-dark-pink) !important;
  text-underline-offset: 0.3vw;
}

.candidateSuccessPopup .modal-content {
  width: 35vw;
  margin: auto;
}

.candidate-popup-submit h5 {
  font-size: var(--size-18px);
}

/* Screening */

/* .form-height-screening {
  min-height: calc(100vh - 13.9vw);
} */

.candidatePresentBtn {
  margin-left: 12.6vw;
}

.matchingCandidateBtn svg,
.candidatePresentBtn svg,
.svgWrapper svg {
  width: 1vw;
  height: 1vw;
}

.salesNoteMeeting * {
  font-family: var(--font-roobert-regular);
  font-size: var(--size-16px);
  color: var(--color-dark-purple);
}

.candidateCornerRightSideBar {
  height: 10vw;
  overflow: auto;
}

/* Manage candidate table */
.manageCandidates th:first-child,
.candidateCentralTable th:first-child {
  width: 7vw !important;
  max-width: 7vw !important;
}

.manageCandidates th:nth-child(2),
.manageCandidates th:nth-child(3),
.manageCandidates th:nth-child(4),
.manageCandidates th:nth-child(5) {
  width: 8%;
}

.manageCandidates th:nth-child(6) {
  width: 8%;
}

.manageCandidates th:nth-child(7) {
  width: 13%;
}

.manageCandidates th:nth-child(8) {
  width: 9%;
}

.manageCandidates th:nth-child(9) {
  width: 6%;
}

.manageCandidates th:nth-child(10) {
  width: 9%;
}

.profileCalendar svg {
  width: 1.25vw !important;
  height: 1.25vw !important;
}


/* candidate central */
.candidateCentralTable th:nth-child(2),
.candidateCentralTable th:nth-child(3),
.candidateCentralTable th:nth-child(4) {
  width: 10%;
}

.candidateCentralTable th:nth-child(5) {
  width: 14%;
}

.candidateCentralTable th:nth-child(6) {
  width: 9%;
}

.candidateCentralTable th:nth-child(7) {
  width: 5%;
}

.candidateCentralTable th:nth-child(8),
.candidateCentralTable th:nth-child(9),
.candidateCentralTable th:nth-child(10),
.candidateCentralTable th:nth-child(11) {
  width: 5%;
}

@media screen and (min-width:1024px) and (max-width:1200px) {

  .manageCandidates th:nth-child(6) {
    width: 15%;
  }

}

.megaPhoneIcon {
  width: 6vw !important;
}

.profilePicUploaded {
  width: 8vw !important;
  height: 8vw !important;
}

.candidateProfilePicUpload.modal.show .modal-dialog {
  min-width: 30vw !important;
  max-width: 30vw !important;
  width: 30vw !important;
}

.remaining-section input:focus {
  border-right: 0.052vw solid transparent !important;
}

.disclaimerText {
  padding: 1vw 0 0vw;
}

.compentencesCandidate {
  margin-bottom: 1vw;
}

.compentencesCandidateBody {
  padding: 1vw;
}

.compentencesCandidateBody label {
  display: none;
}

.compentencesName {
  font-size: 1vw;
}

.matchingCandidateBtn.marginTop0 {
  margin-top: 0 !important;
}