.manageLoginDetails th:nth-child(1) {
  width: 7vw !important;
  max-width: 7vw !important;
}

.manageLoginDetails th:nth-child(2) {
  width: 20%;
}

.manageLoginDetails th:nth-child(3) {
  width: 25%;
}

.manageLoginDetails th:nth-child(4) {
  width: 15%;
}
.manageLoginDetails th:nth-child(5),
.manageLoginDetails th:nth-child(6) {
  width: 12%;
}

.viewLoginDetails th {
  width: 20%;
}

.viewLoginDetails .calendarWithoutLabel {
  width: 70%;
}

.viewLoginDetails .searchFields label {
  display: block !important;
}

.viewLoginDetails .calendarWithoutLabel .calender-icon {
  top:2.25vw !important;
}
.viewLoginDetails .tableSearchFields .calendarWithoutLabel .react-datepicker__input-container input {
  width: 100% !important;
}