.recruiter-action-tabs .card {
  padding: 1.2vw;
}

.recruiter-action-tabs .card svg {
  width: 1.6vw !important;
  height: 1.6vw !important;
}

.card-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0vw 0.104vw 0.417vw 0vw;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease;
  border-radius: 0.5vw;
}

.recruiter-action-tabs .card-shadow:hover,
.tabViewScrollable .card-shadow:hover {
  box-shadow: rgba(229, 0, 125, 0.3) 0vw 0.104vw 0.417vw 0vw;
  background-color: var(--color-dark-pink);
  color: var(--color-white);
}

.recruiter-action-tabs a {
  color: var(--color-dark-purple);
  text-decoration: none !important;
}

.recruiter-action-tabs .card-shadow:hover a {
  color: var(--color-white);
}

.countIcon {
  background-color: var(--circlePrimaryColor);
  color: var(--color-dark-pink);
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-roobert-semibold);
  margin-left: 0.25vw;
}