.defaultCoefficientTableRightSection thead,
.defaultCoefficientTableLeftSection thead {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
}
.col-fixed {
  background-color: var(--color-white);
  left: 0;
  position: sticky;
  z-index: 9;
}

.defaultCoefficientHeight {
  height: calc(100vh - 17.5vw);
  overflow: auto;
}

.defaultCoefficientTableLeftSection thead tr th,
.defaultCoefficientTableLeftSection thead tr,
.defaultCoefficientTableRightSection thead tr th,
.defaultCoefficientTableRightSectionthead tr {
  outline: 0.052vw solid var(--header-bg-table-color);
  border: 0.052vw solid var(--header-bg-table-color) !important;
}

.defaultCoefficientTableLeftSection thead tr th,
.defaultCoefficientTableRightSection thead tr th,
.defaultCoefficientTableLeftSection tbody tr th,
.defaultCoefficientTableLeftSection tbody tr td,
.defaultCoefficientTableRightSection tbody tr th,
.defaultCoefficientTableRightSection tbody tr td {
  padding: 0.5vw;
  font-size: var(--size-16px) !important;
}

.employee-type-coefficient,
.low-default-coefficient-right-section tbody td {
  height: 2.5vw;
}

.defaultCoefficientTableRightSection .TableHeader:nth-child(2) th {
  min-width: 10vw;
}


.pc-coefficient-level:not(:last-child) {
  border-bottom: 1px solid var(--header-bg-table-color);
}

.defaultCoefficientTableRightSection .form-group .col-md-12 {
height: 2.5vw;
}

.defaultCoefficientTableRightSection .form-group .col-md-12 input:focus {
  outline: 0 !important;
  border: 0.052vw solid var(--color-dark-purple) !important;
}

.defaultCoefficientTableRightSection .form-group {
  padding: 0 !important;
}

.defaultCoefficientTableLeftSection thead th:nth-child(1) {
  width: 35%;
}

.defaultCoefficientTableLeftSection thead th:nth-child(2) {
  width: 35%;
}

.defaultCoefficientTableRightSection {
  z-index: 0;
}

.low-default-right tbody  tr td {
  height: 2.5vw;
}

.low-default-left-th {
  padding: 0.626vw !important;
}

.defaultCoefficientTableLeftSection th {
  padding: 0 !important;
}