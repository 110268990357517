
/* table */
.table td,
.table td a {
  font-size: var(--size-16px);
  color: var(--color-dark-purple);
  transition: color 0.3s ease;
}

.table td {
  padding-top: 0.5vw !important;
  padding-bottom: 0.5vw !important;
}

.table td a:hover {
  color: var(--color-dark-pink);
}

.table-action-icons svg {
  width: 2vw;
  height: 2vw;
  background-color: var(--circlePrimaryColor);
  border-radius: 50%;
  color: var(--color-dark-pink) !important;
  padding: 0.4vw;
  transition: all 0.3s ease;
}

.table-action-icons svg:hover {
  background-color: var(--color-dark-pink) !important;
  color: var(--circlePrimaryColor) !important;
}
.tableMainWrapper {
  margin-top: 1vw;
}

.tableSection {
  border: 0.052vw solid #e6e9ec;
  border-radius: 0.5vw;
  padding: 1.3vw 1.2vw 0.6vw;
}

.TableHeader {
  background-color: var(--budget-bg);
  vertical-align: middle;
  position: relative;
  top: -0.04vw;
}

.TableHeader th {
  background-color: initial !important;
  color: var(--color-dark-purple) !important;
  font-size: var(--size-18px);
  font-weight: 400 !important;
}

.table>:not(caption)>*>* {
  padding: 0.3vw !important;
}

.tableSection td {
  word-break: break-all !important;
}


.tableContainer {
  height: calc(100vh - 19.6vw);
  overflow: auto;
}

.tableContainerWithoutTabs {
  height: calc(100vh - 18.6vw);
  overflow: auto;
}

.tableSectionWithoutBtn {
  padding-top: 0.5vw !important;
}

.tableSectionWithoutBtn .tableContainer {
  height: calc(100vh - 19.5vw);
  overflow: auto;
}

.tableContainer thead {
  position: sticky;
  top: 0;
  background: var(--color-white);
  z-index: 99;
}

.tableSearchFields th:nth-child(1) {
  padding-left: 0.08vw !important;
}

.tableSearchFields .filter-group:not(:last-child) {
  margin-right: 0.5vw;
}

.tableSearchFields .filter-group {
  width: 50%;
}
