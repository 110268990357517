/* button design */
.form-button {
  background-color: var(--color-dark-pink) !important;
  border: 0.052vw solid var(--color-dark-pink) !important;
  color: var(--color-white) !important;
  min-height: 2.344vw;
  padding: 0.5vw !important;
  font-size: var(--size-16px) !important;
  border-radius: 0.4vw;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: var(--circlePrimaryColor) !important;
  color: var(--color-dark-pink) !important;
}

/* Reset and search btn */
.delete-btn,
.modal-cancel-btn {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-dark-purple) !important;
  color: var(--color-dark-purple) !important;
  height: 2.344vw;
  transition: all 0.3s ease-in-out;
  border-radius: 0.4vw;
  margin-right: 0.5vw;
  min-width: 3vw;
}

.deletebtn {
  background-color: var(--color-red) !important;
  border: 1px solid var(--color-red) !important;
  color: var(--color-white) !important;
  height: 2.344vw;
  transition: all 0.3s ease-in-out;
  border-radius: 0.4vw;
  margin-right: 0.5vw;
  padding: 0 0.5vw;
  min-width: 3vw;
}

.deletebtn:hover {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
}

.delete-btn:hover,
.modal-cancel-btn:hover {
  background-color: var(--color-dark-purple) !important;
  color: var(--color-white) !important;
}

.back-btn {
  color: var(--color-dark-purple);
  font-size: 0.9vw;
}

.form-manage-button,
.formManageBtn .multiSelectDropdown__control {
  background-color: var(--budget-bg);
  border: 0.052vw solid var(--circlePrimaryColor) !important;
  box-shadow: 0 0.104vw 0.104vw 0 #63636333;
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
  flex-basis: 0;
  flex-grow: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw !important;
}

.formManageBtn .multiSelectDropdown__control {
  padding-left: 2vw !important;
}

.form-manage-button:hover,
.formManageBtn .multiSelectDropdown__control:hover {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
  cursor: pointer;
}

.formManageBtnWrapper svg {
  color: var(--color-dark-pink);
}

.formManageBtnWrapper:hover svg {
  color: var(--color-white) !important;
}

.formManageBtnWrapper:hover .multiSelectDropdown__dropdown-indicator {
  background-image: url("../images/dropdownWhite.svg") !important;
}

.formManageBtn .multiSelectDropdown__control:hover .multiSelectDropdown__single-value {
  color: var(--color-white) !important;
}

.formManageBtn .multiSelectDropdown__control .multiSelectDropdown__single-value {
  color: var(--color-dark-pink) !important;
}

.formManageBtnWrapper .multiSelectDropdown__control>div.multiSelectDropdown__indicators {
  position: absolute;
  right: -0.5vw;
  top: 0.7vw;
}

.search-btns {
  min-width: 8vw;
}

.delete-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.manageBtn {
  background-color: var(--circlePrimaryColor);
  border: 0.052vw solid var(--circlePrimaryColor) !important;
  color: var(--color-dark-pink) !important;
  min-height: 2.344vw;
  padding: 0.5vw !important;
  font-size: var(--size-16px) !important;
  border-radius: 0.4vw;
  transition: all 0.3s ease;
}

.manageBtn:hover {
  background-color: var(--color-dark-pink);
  border: 0.052vw solid var(--color-dark-pink) !important;
  color: var(--circlePrimaryColor) !important;
}

.delete-btn svg {
  width: 1.3vw;
  height: 1.3vw;
}

.search-btns svg {
  position: absolute;
  right: 1vw;
  top: 0.7vw;
}

/* Manage page create Btn */

.ManageCreateBtn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1vw;
}

.ManageCreateBtn a:hover {
  text-decoration: none !important;
}

.backBtn {
  background-color: transparent;
}

.advSearchBtn svg {
  color: var(--color-white) !important;
}

.headerBtn {
  width: max-content !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
}


/* Modal */
/* Modal */

.modal-title {
  color: var(--color-dark-pink) !important;
  font-size: var(--size-20px) !important;
}

.modal-header .modal .btn-close:focus {
  box-shadow: none !important;
}

.modal-border {
  border-bottom: 0.052vw solid var(--modal-border-color) !important;
}

.modal-content {
  border-radius: 0.521vw !important;
  padding: 0.417vw 2.5vw !important;
  background-color: var(--popup-bg) !important;
  border: 0vw !important;
}

.modal-body {
  font-size: var(--size-18px);
  padding: 1vw 0 !important;
}

.modal .btn {
  min-width: 3.333vw;
  font-size: var(--size-16px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw !important;
  padding-left: 0.5vw !important;
  padding-right: 0.5vw !important;
}

.modal .btn-close {
  background: var(--color-dark-pink) center/1em auto no-repeat !important;
  --bs-btn-close-bg: none !important;
  background-image: url("../images/ModalClose.svg") !important;
  position: absolute;
  left: 50%;
  top: -0.521vw;
  opacity: 1;
  padding: 0.5vw !important;
  border-radius: 0.5vw !important;
  transform: translate(-50%, -0%);
}

.modal .btn-close:focus {
  box-shadow: none !important;
}

.modal-backdrop {
  background-color: #fff !important;
  --bs-backdrop-opacity: 0.75 !important;
}


/* No Button */

.modal-cancel-btn,
.delete-btn {
  border: 0.052vw solid var(--color-dark-purple) !important;
  color: var(--color-dark-purple) !important;
  background-color: var(--color-white) !important;
  height: 2.344vw;
  padding-left:0.35vw;
  padding-right: 0.35vw;
}

.modal-cancel-btn:hover,
.delete-btn:hover {
  background-color: var(--color-dark-purple) !important;
  color: var(--color-white) !important;
  transition: all 0.3s ease-in-out;
}

/* Yes Button */

.modal-confirm-btn,
.edit-btn {
  border: 0.052vw solid var(--color-dark-pink) !important;
  background-color: var(--color-dark-pink) !important;
  color: var(--color-white) !important;
  height: 2.44vw;
  border-radius: 0.4vw !important;
}

.modal-confirm-btn:hover,
.edit-btn:hover {
  color: var(--color-dark-pink) !important;
  background-color: var(--color-white) !important;
  transition: all 0.3s ease-in-out;
}

.marginRight1 {
  margin-right: 1vw;
}

.marginLeft1 {
  margin-left: 1vw;
}

.marginTop1 {
  margin-top: 1vw !important;
}

.marginBotttom1 {
  margin-bottom: 1vw !important;
}

.marginBottomPoint5 {
  margin-bottom: 0.5vw !important;
}

.marginRightPoint5 {
  margin-right: 0.5vw !important;
}

.padding1 {
  padding: 1vw !important;
}

.paddingTop2 {
  padding-top: 2vw !important;
}

.paddingBottom1 {
  padding-bottom: 1vw !important;
}

.marginLeft1 {
  margin-left: 1vw !important;
}

.merginLeft2 {
  margin-left: 2.5vw !important;
}