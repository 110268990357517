/* Multiselect field */
.multiSelectDropdown__multi-value__label {
  font-size: 0.8vw !important;
}

.multiSelectDropdown__control--is-focused {
  border-color: var(--color-dark-pink) !important;
  box-shadow: 0 0 0 0.052vw var(--shadowColor) !important;
}

.multiSelectDropdown__dropdown-indicator,
.form-select {
  background-image: url("../images/dropdown.svg") !important;
  background-size: 0.677vw !important;
  background-repeat: no-repeat;
  background-position: 0vw;
  cursor: pointer;
  padding-right: 1vw !important;
}

.multiSelectDropdown__dropdown-indicator svg {
  display: none;
}

.multiSelectDropdown__dropdown-indicator {
  padding: 0.5vw !important;
}

.multiSelectDropdown__clear-indicator svg {
  fill: var(--color-dark-pink);
  width: 1.2vw !important;
  height: 1.2vw !important;
  cursor: pointer;
}

.wageElementsClass .multiSelectDropdown__clear-indicator svg {
  height: 2.2vw !important;
}

.pi-times:before {
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
}

.multiSelectDropdown__control>div.multiSelectDropdown__indicators {
  min-width: 1.5vw;
}

.multiSelectDropdown__value-container {
  padding: 0 0.5vw !important;
}

.multiSelectDropdown__input-container,
.multiSelectDropdown__placeholder {
  margin-left: 0px !important;
}

.tableSearchFields .p-multiselect {
  display: flex;
  align-items: center;
}

.tableSearchFields .p-multiselect-label-container {
  padding-left: 0.25vw !important;
  padding-right: 0.25vw !important;
}

/* AutoCompleteStyles.css */
.p-autocomplete-panel {
  width: 100% !important;
  box-sizing: border-box;
  z-index: 1500 !important;
}

.p-autocomplete-item {
  white-space: pre-wrap !important;
  font-size: 0.85vw;
  padding: 0.5vw;
}

.p-autocomplete .p-autocomplete-multiple-container,
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0;
}

/* Auto complete */

ul.p-autocomplete-multiple-container {
  box-shadow: rgba(241, 216, 232, 1) 0vw 0.052vw 0.104vw 0vw,
    rgba(241, 216, 232, 1) 0vw 0.052vw 0.156vw 0.052vw !important;
  border: 0.052vw solid var(--color-white) !important;
  border-radius: 0.417vw;
  min-height: 2.344vw;
  /* padding-left: 0.521vw !important; */
}

ul.p-autocomplete-items {
  padding-left: 0vw !important;
  margin-bottom: 0vw !important;
}

.p-autocomplete-token.p-highlight {
  margin: 0vw 0vw 0;
  background: #e5e5e5 !important;
  display: flex;
  flex: 1 1 auto;
}

span.p-autocomplete-token-label {
  width: 90%;
}

.p-autocomplete-loader {
  position: absolute;
  right: 0.521vw;
  top: 50%;
}

.p-autocomplete-loader path {
  fill: var(--linkColor) !important;
}

.p-autocomplete-multiple-container {
  margin-bottom: 0vw !important;
}

.autoCompleteFunction .p-inputwrapper {
  box-shadow: rgba(241, 216, 232, 1) 0vw 0.052vw 0.104vw 0vw,
    rgba(241, 216, 232, 1) 0vw 0.052vw 0.156vw 0.052vw !important;
  border: 0.052vw solid var(--color-white) !important;
  border-radius: 0.417vw;
  box-shadow: none !important;
}

.autoCompleteFunction .p-inputwrapper input {
  border: 0vw !important;
  border-radius: 0.417vw;
  box-shadow: rgba(241, 216, 232, 1) 0vw 0.052vw 0.104vw 0vw,
    rgba(241, 216, 232, 1) 0vw 0.052vw 0.156vw 0.052vw !important;
}

.autoCompleteFunction .p-autocomplete-multiple input {
  box-shadow: none !important;
}

.autoCompleteInput {
  font-family: var(--font-roobert-regular);
  font-size: 0.8vw;
  padding: 0.6vw 0.45vw;
}


li.p-autocomplete-item-group {
  padding: 0.5vw !important;
  font-size: 0.8vw !important;
}

.manageCompanies .multiSelectDropdown__placeholder {
  width: 2.3vw !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-multiselect .p-multiselect-trigger {
  display: none;
}

.p-checkbox-box svg {
  width: 1vw !important;
  height: 1vw !important;
}

.multiSelectDropdown__option {
  font-size: 0.85vw !important;
  padding: 0.5vw !important;
}

.css-16xfy0z-control,
.disabled input[type="tel"],
.form-control:disabled,
.form-select:disabled,
.time-section .hourWrapper select:disabled,
.time-section .minuteWrapper select:disabled,
input:disabled,
.form-control.disabled,
.multiSelectDropdown__control--is-disabled {
  background-color: #f5e9ef !important;
  border: 0.052vw solid #f5e9ef !important;
}

.time-section select:focus {
  border: 0.052vw solid var(--shadowColor) !important;
  cursor: pointer;
}

.time-section select:focus-visible {
  outline: 0;
}

/* multi select with checkbox */
.p-multiselect-items {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.p-multiselect-filter {
  box-shadow: none;
  box-shadow: rgba(241, 216, 232, 1) 0vw 0.052vw 0.104vw 0vw,
    rgba(241, 216, 232, 1) 0vw 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.5vw;
  border: 0.052vw solid var(--circlePrimaryColor);
  padding: 0.25vw;
  min-height: 2.344vw;
}

.p-multiselect-filter:hover {
  border: 0.052vw solid var(--color-dark-pink) !important;
}

.p-multiselect-filter-container svg {
  display: none !important;
}

.p-multiselect-label {
  height: 100%;
  max-width: 80%;
}

.p-multiselect-label-container {
  height: 2.25 !important;
  max-width: 100%;
}

.p-multiselect-item,
.p-multiselect-filter {
  font-size: var(--size-16px);
  padding: 0.5vw !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  padding: 0.15vw;
  border-radius: 0 !important;
}

.clear-selection-button {
  font-size: var(--size-16px);
}

.p-checkbox {
  align-items: center;
  width: 1.2vw !important;
  height: 1.4vw !important;
}

.p-multiselect-items-wrapper {
  max-height: 10vw !important;
}

.p-multiselect-panel {
  width: 16vw !important;
  margin-top: 0.5vw !important;
}

.p-multiselect-item span {
  white-space: break-spaces;
}

.multiSelectDropdown__menu-list {
  max-height: 15vw !important;
}

.multiSelectDropdown__multi-value__remove {
  padding-left: 0.4vw !important;
  padding-right: 0.4vw !important;
}
.multiSelectDropdown__multi-value__remove svg {
  width: 0.8vw;
  height: 0.8vw;
}

.multiSelectDropdown__multi-value {
  margin: 0.3vw !important;
}

.p-virtualscroller {
  height: 15vw !important;
}