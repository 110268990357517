.multipleChoiceQns .ck-editor__editable_inline, .radioFieldQns .ck-editor__editable_inline {
  height: 4vw;
}

.questionOptionsInput p,
.questionOptions p {
  margin-bottom: 0 !important;
}

.questionName {
  font-size: 1vw !important;
}