  .custom-toggle-container>div {
    width: 4.5vw !important;
    margin: auto;
  }

  .custom-toggle-container>div>div:first-child {
    width: 4.5vw !important;
    height: 1.85vw !important;
    border-radius: 1vw !important;
  }

  .custom-toggle-container>div>div:not(:first-child)>div {
    width: 1.5vw !important;
    height: 1.5vw !important;
  }

  .custom-toggle-container.active>div>div:not(:first-child)>div {
    left: 3vw !important;
  }

  .custom-toggle-container>div>div:first-child div {
    font-size: 0.8vw !important;
    left: 0.15vw !important;
    height: 1vw !important;
    width: 1.5vw !important;
    font-family: var(--font-roobert-semibold) !important;
  }

.postScreeingQns {
    font-size: var(--size-18px);
    font-family: var(--font-roobert-semibold);
}