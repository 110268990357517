.jobApplicationModal .modal-content {
  max-width: 40vw;
  margin: auto;
}

.manageApplications th:first-child {
  width: 7vw !important;
  max-width: 7vw !important;
}
.manageApplications th:nth-child(6),
.manageApplications th:nth-child(2) {
  width: 14%;
}

.manageApplications th:nth-child(3) {
  width: 17%;
}
.manageApplications th:nth-child(4),
.manageApplications th:nth-child(5),

.manageApplications th:nth-child(8) {
  width: 10% !important;
}

.manageApplications th:nth-child(7){
  width: 10%;
}