.designationTable .designationName {
  background-color: var(--circlePrimaryColor);
  color: var(--color-dark-pink);
  transition: background-color 0.5s ease, color 0.5s ease;
  border-radius: 0.4vw;
  padding: 0.5vw;
  width: 94%;
  word-break: break-all;
}

.designationTable .designationName button {
  color: var(--color-dark-pink);
  transition: color 0.5s ease;
}

.designationTable .designationName button svg {
  width: 1vw;
}

.designationTable .designationName:hover {
  background-color: var(--color-dark-pink);
  color: var(--color-white);
}

.designationTable .designationName:hover button {
  color: var(--color-white);
}


.create-role-tabs .btn {
  font-size: var(--size-16px) !important;
}

.create-role-tabs .btn.active, .create-role-tabs .btn:hover {
  color: var(--color-dark-pink);
  text-decoration: underline;
  text-decoration-thickness: 0.104vw;
  text-underline-offset: 0.208vw;
}

.create-roles .height-20 {
  display: none;
}

.create-roles th:first-child {
  width: 25%;
}

.create-roles th:not(:first-child) {
  width: 15%;
}

.manage-roles th:first-child {
  width: 60%;
}

.create-role-height {
  min-height: calc(100vh - 13vw);
}