/* Link language */

.csvReader .row:first-child .col-md-12 div {
  min-height: 5vw !important;
}

.selected-options-container {
  white-space: break-spaces;
}

.selected-options-container span {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 4vw;
  font-size: var(--size-16px);
}

.custom-multi-select .dropdown-heading {
  height: fit-content !important;
  min-height: 2.44vw;
}

.custom-multi-select .rmsc .dropdown-container:focus-within {
  border: 0.052vw solid var(--shadowColor) !important;
  box-shadow: none !important;
}

.custom-multi-select .clear-selected-button svg line {
  color: var(--color-red);
}


.custom-multi-select .dropdown-heading-dropdown-arrow {
  stroke-width: 0;
  background-image: url("../images/dropdown.svg");
  background-repeat: no-repeat;
  background-size: 0.7vw;
  background-position: center;
  cursor: pointer;
  margin-left: 0.5vw;
  width: 0.8vw;
  height: 0.8vw;
}

.custom-multi-select .rmsc .dropdown-container {
  border: 0.052vw solid var(--shadowColor) !important;
  border-radius: 0.4vw !important;
}

.removeCSV svg {
  width: 1vw !important;
  height: 1vw;
}
