.edit-pc-structure ul {
  position: relative;
}

.structure-li li::before {
  border-left: 0.052vw solid #999;
  bottom: 2.604vw;
  height: 100%;
  top: 0vw;
  width: 0.052vw;
  content: '';
  left: -1.719vw;
  position: absolute;
  right: auto;
}

.structure-li::before,
.structure-li > ul > li::before,
.structure-li::after,
.structure-li > ul > li::after {
  display: none;
}

.manage-pc-domain::after {
  color: pink;
  border-top: 0.052vw solid #999;
  height: 1.042vw;
  top: 2.188vw;
  width: 2.604vw;
  content: '';
  left: -1.667vw;
  position: absolute;
  right: auto;
}

.manage-pc-function-with-domain::before {
  border-left: 0.052vw solid #999 !important;
  bottom: 2.604vw !important;
  height: 100% !important;
  top: -0.885vw !important;
  width: 0.052vw !important;
  content: '' !important;
  left: 1.51vw !important;
  position: absolute !important;
  right: auto !important;
}

.domain-function-section::after {
  color: pink;
  border-top: 0.052vw solid #999;
  height: 1.042vw;
  top: 1.302vw;
  width: 1.042vw;
  content: '';
  left: -0.99vw;
  position: absolute;
  right: auto;
}

.pc-buffer-time .input-group-text {
  height: 2.25vw !important;
  right: 0.05vw;
  top: 0.05vw;
  background-color: var(--header-bg-table-color);
  min-width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
}

.maxWorkTiming .input-group-text {
  height: 2.25vw !important;
  right: 0.05vw;
  top: 1.75vw;
  background-color: var(--header-bg-table-color);
  min-width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
}

.salaryBenefitValue .input-group-text {
  top: 1.76vw !important;
  min-width: 2vw !important;
}

.pcEndTime .pc-buffer-time .input-group-text {
  min-width: 5vw !important;
  font-size: 0.8vw !important;
}
.time-section input {
  box-shadow: 0 0.052vw 0.104vw 0 #f1d8e8, 0 0.052vw 0.156vw 0.052vw #f1d8e8 !important;
  font-size: var(--size-16px) !important;
}

.time-section input:focus {
  border: 0.052vw solid var(--circlePrimaryColor) !important;
  outline: 0 !important;
}

.salaryBenefit label {
  display: none;
}

.ecoCheque label {
  display: none;
}

.mealVouchers .input-group-text {
  height: 2.35vw !important;
  right: 0vw;
  top: 1.7vw;
  background-color: var(--header-bg-table-color);
  width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
}

.ecoVouchers .input-group-text {
  height: 2.35vw !important;
  right: 0vw;
  top: 0vw;
  background-color: var(--header-bg-table-color);
  width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
  z-index: 10;
}

.select-bar-salary .height-20,
.select-bar-salary label {
  display: none;
}
