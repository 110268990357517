.manageUsers th:first-child {
  width: 7vw;
}

.manageUsers th:nth-child(2),
.manageUsers th:nth-child(3),
.manageUsers th:nth-child(5) {
  width: 10%;
}

.manageUsers th:nth-child(4) {
  width: 22%;
}

.manageUsers th:nth-child(6) {
  width: 7%;
}

.manageUsers th:nth-child(7) {
  width: 12%;
}