body {
  font-size: var(--size-18px) !important;
  font-family: var(--font-roobert-regular) !important;
  background: var(--sideBarBgColor) !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

* {
  margin: 0;
  padding: 0;
}
:root {

   /* Color  */
   --lightGray:#f5f5f5;
  --textPrimary: #002B31;
  --color-dark-purple: #00182C;
  --color-black: #000;
  --color-white: #fff;
  --color-light-pink: #ff4dae;
  --color-baby-pink: #fbb0d3;
  --color-dark-pink:#e55496;
  --color-red: #DC381F;
  --header-bg-table-color: #E3E6E8;
  --action-icon-color: #999c9f;
  --tiles-bg-color: #4c4d550f;
  --modal-border-color: #0006;
  --header-border: #999c9f63;
  --primaryBrandColor: #e55496;
  --circlePrimaryColor: #fbf0f6;
  --circleSecondaryColor: #eae8ff;
  --descriptionTextColor: #ADADAD;
  --shadowColor: #f1d8e8;
  --btnTextColor: #cf5da6;
  --linkColor: #cf8aba;
  --sideBarBgColor: #fdf7f9;
  --profileBgColor: #fbeef6;
  --budget-bg: #f6f6f8;
  --option-bg:#DEEBFF;
  --popup-bg:#FFEFF7;

   /* Font size  */
  --size-14px: 0.729vw;
  --size-15px: 0.781vw;
  --size-16px: 0.833vw;
  --size-18px: 0.938vw;
  --size-20px: 1.042vw;
  --size-22px: 1.146vw;
  --size-24px: 1.25vw;
  --size-25px: 1.302vw;
  --size-26px: 1.354vw;
  --size-28px: 1.458vw;
  --size-30px: 1.563vw;
  --size-32px: 1.667vw;
  --size-34px: 1.771vw;
  --size-38px: 1.979vw;
  --size-36px: 1.875vw;
  --size-40px: 2.083vw;
  --size-46px: 2.396vw;
  --size-44px: 2.292vw;
  --size-50px: 2.604vw;
  --size-60px: 2.604vw;
  --size-160px: 8.333vw;

  --spacing:1.25vw;
  --radius:0.521vw;

  /* Font family  */
  --font-fraunces-medium-italic: "fraunces-medium-italic";
  --font-fraunces-semibold-italic: "fraunces-semibold-italic";
  --font-roobert-bold: "roobert-bold";
  --font-roobert-regular: "roobert-regular";
  --font-roobert-semibold: "roobert-semibold";
}

@font-face {
  font-family: "roobert-bold";
  src: url(../fonts/Roobert/Roobert-Bold.otf);
}

@font-face {
  font-family: "roobert-regular";
  src: url(../fonts/Roobert/Roobert-Regular.otf);
}

@font-face {
  font-family: "roobert-semibold";
  src: url(../fonts/Roobert/Roobert-SemiBold.otf);
}

@font-face {
  font-family: "fraunces-semibold-italic";
  src: url(../fonts/Fraunces/static/Fraunces_144pt-SemiBoldItalic.ttf);
}

@font-face {
  font-family: "fraunces-medium-italic";
  src: url(../fonts/Fraunces/static/Fraunces_9pt-BoldItalic.ttf);
}

.fraunces-italic {
  font-family: var(--font-fraunces-medium-italic);
}

.color-dark-pink {
  color: var(--color-dark-pink);
}

.color-dark-purple {
  color: var(--color-dark-purple);
}

.dashboard-myBudget-wrapper {
  background-color: var(--budget-bg) !important;
}

.bg-pink {
  background-color: var(--primaryBrandColor) !important;
}

.size-14px{
  font-size: var(--size-14px);
}

.mt-35 {
  margin-top: 1.823vw !important;
}

.mt-34 {
  margin-top: 1.7vw !important;
}

.roobert-semibold {
  font-family: var(--font-roobert-semibold);
}

table td.activeRecruiter {
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
  font-family: var(--font-roobert-semibold);
  background-color: var(--shadowColor);
}

div.paddingRightPoint5 {
  padding-right: 0.5vw !important;
}