.manageSalesNotes th:first-child {
    width: 7vw !important;
    max-width: 7vw !important;
}

.manageSalesNotes th:nth-child(3),
.manageSalesNotes th:nth-child(6),
.manageSalesNotes th:nth-child(7),
.manageSalesNotes th:nth-child(2),
.manageSalesNotes th:nth-child(4) {
    width: 14%;
}

.manageSalesNotes th:nth-child(5) {
    width: 15%;
}

.manageSalesNotes .tableSearchFields .date-selected input {
  width: 4.5vw !important;
}