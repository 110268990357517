.form-control:hover {
  font-size: 1vw;
}

.form-control {
  min-height: 2.344vw;
  border: 0.052vw solid var(--color-white) !important;
  padding: 0.5vw !important;
  font-size: 0.8vw !important;
  border-radius: 0.4vw !important;
}

.field-shadow,
.date-selected .react-datepicker__input-container {
  box-shadow: rgba(241, 216, 232, 1) 0vw 0.052vw 0.104vw 0vw,
    rgba(241, 216, 232, 1) 0vw 0.052vw 0.156vw 0.052vw !important;
}

.form-control:focus {
  border: 0.052vw solid var(--shadowColor) !important;
}

::-webkit-input-placeholder {
  color: var(--action-icon-color) !important;
  font-size: 0.8vw !important;
}

.field-label {
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-regular);
  font-size: var(--size-18px);
}

.form-label {
  margin-bottom: 0.3vw !important;
}

.height-20 {
  min-height: 1.2vw;
}

/* Link color */
.link-color {
  color: var(--color-dark-pink);
  font-size: var(--size-18px);
}

/* Phone input */

.PhoneInputInput {
  border: 0 !important;
}

.PhoneInput--focus .PhoneInputInput {
  outline: 0 !important;
  border: 0 !important;
}

/* Checkbox */
.form-check-input,
.p-checkbox-box {
  border: 0.052vw solid var(--primaryBrandColor) !important;
  height: 1vw !important;
  width: 1vw !important;
}

.form-check-input:checked,
.p-checkbox.p-highlight .p-checkbox-box {
  background-color: var(--color-dark-pink) !important;
  border: 0.053 solid var(--color-dark-pink) !important;
  accent-color: var(--color-dark-pink);
}

.form-check-input:disabled {
  border: 0.052vw solid var(--color-dark-pink) !important;
  background-color: var(--color-dark-pink) !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* radio Btn */
.form-check-input:checked[type="radio"] {
  background-color: initial !important;
  background-image: url("../images/radio-checked.svg");
  background-size: 1vw !important;
  border-color: #0000 !important;
}

.form-check-input:disabled[type="radio"] {
  background-color: transparent !important;
}

/* Status icon */
.status-icon {
  width: 0.8vw;
  height: 0.8vw;
}

.search-bar .height-20 {
  margin-bottom: 0vw !important;
}

.hotlistWrapper {
  margin-top: 2.15vw !important;
}

.text-danger {
  font-size: var(--size-16px);
}

/* Accordion */
.accordion-button {
  box-shadow: none !important;
  font-size: var(--size-18px) !important;
  padding: 1vw !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--color-dark-pink) !important;
  color: var(--color-white) !important;
}

.accordion-button:not(.collapsed):after {
  background-image: url("../images/arrow-down.svg") !important;
  background-position: 50%;
  background-size: 1vw !important;
  transform: rotate(180deg);
}

.documentsCertificates.modal.show .modal-dialog {
  max-width: 55vw !important;
  min-width: 55vw !important;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar-container {
  background-color: var(--shadowColor);
  border-radius: 0.5vw;
  width: 100%;
}

.progress-bar {
  background-color: var(--color-dark-pink) !important;
  border-radius: 0.5vw;
  height: 0.5vw;
}

/* Time select */
.time-section select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--color-white) !important;
  background-image: url("../images/dropdown.svg") !important;
  background-position: right 0.521vw bottom 0.625vw;
  background-repeat: no-repeat;
  background-size: 0.677vw;
  border: 0.052vw solid var(--shadowColor) !important;
  min-height: 2.344vw;
  border-radius: 0.4vw !important;
}

.hourWrapper:not(.todoTimePicker .hourWrapper) {
  margin-right: 0.5vw;
}

.minuteWrapper:not(.todoTimePicker .minuteWrapper) {
  margin-left: 0.5vw;
}

.placeholder-color {
  color: var(--action-icon-color) !important;
}

.value-color {
  color: var(--color-dark-purple) !important;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 0.052vw solid var(--shadowColor) !important;
}

.accordion-button::after {
  width: 1vw !important;
  height: 1vw !important;
  background-size: 1vw !important;
}

.emailTemplateLanguage .dropdown-toggle {
  border: 0.052vw solid var(--color-dark-purple) !important;
  font-size: var(--size-16px);
}

.viewEmailTemplate .ck a {
  color: var(--color-dark-pink) !important;
  font-family: var(--font-roobert-semibold);
}


.headerIcons svg {
  width: 1vw !important;
  height: 1vw !important;
}


.advancedSearchModal .modal-dialog {
  min-width: 70vw;
  max-width: 70vw;
}

.hideHeight .height-20 {
  display: none;
}

.advancedSearchModal .modal-body {
  max-height: calc(100vh - 20vw);
  overflow: auto;
}


.calendarWithoutLabel .calender-icon {
  top: 0.45vw !important;
  right: 0vw;
}

.calendarWithoutLabel .calender-icon svg {
  width: 0.8vw !important;
  height: 0.8vw !important;
}

.calendarWithoutLabel .input-group-text {
  padding: 0.3vw !important;
}

.tooltip-inner {
  font-size: var(--size-16px) !important;
}

.spinnerIcon {
  width: 1vw !important;
  height: 1vw !important;
}

/* text overflow */
.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal.show .modal-dialog {
  min-width: 45vw !important;
  max-width: 45vw !important;
  width: 45vw !important;
}

.inputGroup .input-group-append {
  background-color: var(--header-bg-table-color) !important;
  height: 2.344vw;
  position: relative;
  top: 0vw;
  border-bottom-right-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
}

.inputGroup .input-group-append .input-group-text {
  height: 99%;
  background-color: var(--header-bg-table-color) !important;
  font-size: 0.8vw !important;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
}

.backPadding {
  padding: 1vw 0 !important;
}

.emailTemplateLanguage .dropdown-item  {
  font-size: 0.8vw !important;
  padding: 0.5vw !important;
}

.emailTemplateLanguage .dropdown-menu {
  min-width: 10vw !important;
  padding: 0.5vw !important;
}

.p-multiselect-empty-message {
  font-size: 0.8vw;
}


.react-datepicker {
  font-size: var(--size-14px) !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  margin: 0.2vw !important;
  width: 1.5vw !important;
  line-height: 1.5vw !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 0.8vw !important;
}

.CustomSelectWidth .multiSelectDropdown__menu {
  width: 15vw;
}

.iframeHeight {
  height: calc(100vh - 9vw);
}

.iframeHeight * {
  font-size: var(--size-18px) !important;
}

.bubble {
  background: var(--color-dark-pink);
  color: var(--color-white);
  padding: 0.5vw 1vw;
  position: absolute;
  border-radius: 0.3vw;
  left: 50%;
  transform: translateX(-50%);
  top: 1vw;
}

.bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: var(--color-dark-pink);
  top: -1px;
  left: 50%;
}

/* Range bar  */

.form-range {
  appearance: auto !important;
  width: 100%; /* Adjust based on container width */
}

.sliderPink,
.sliderPink:hover {
  accent-color: var(--color-dark-pink) !important;
  background-color: var(--color-dark-pink) !important;
}

.range-wrap .bubble {
  display: none;
}

.range-wrap:hover .bubble {
  display: block;
  z-index: 999;
}

.form-range::-webkit-slider-thumb {
  background-color: var(--color-dark-pink) !important;
  box-shadow: none;
  width: 1vw !important;
  height: 1vw !important;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: none !important;
}

.compentences .form-range {
  height: 0.5vw !important;
}

.compentences .form-range::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--color-dark-pink) 0%,
    var(--color-dark-pink) calc(var(--value, 0%) * 1%),
    #999c9f00 calc(var(--value, 0%) * 1%),
    #999c9f00 100%
  ) !important;
  min-height: 0.5vw !important;
}

::-webkit-inner-spin-button {
  display: none;
}

.range-wrap {
  position: relative;
}

.form-range::-webkit-slider-runnable-track {
  height: 0.5vw !important;
  background: none !important;
}

.form-range {
  -webkit-appearance: none; /* Remove default styling */
  height: 8px; /* Adjust the height of the slider track */
  background: transparent; /* Make background transparent */
}

.form-range::-webkit-slider-runnable-track {
  height: 8px; /* Height of the track */
  background: #007bff; /* Color of the track */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default thumb styling */
  height: 20px; /* Height of the thumb */
  width: 20px; /* Width of the thumb */
  background: #fff; /* Thumb color */
  border: 2px solid #007bff; /* Thumb border */
  border-radius: 50%; /* Optional: Make the thumb circular */
  margin-top: -6px; /* Center the thumb over the track */
  cursor: pointer; /* Change cursor on hover */
}

.alert-dismissible .btn-close {
  padding: 0.5vw !important;
}
.pac-container.pac-logo {
  z-index: 1500;
}