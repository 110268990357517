/* Profile Card Image Sizes */
.profileCardImage {
    width: 1.5vw !important;
    height: 1.5vw !important;
}

/* Text Background Styling */
.textAppendBg {
    display: flex;
    align-items: center;
    padding: 0 0.8vw;
    background-color: var(--header-bg-table-color) !important;
    border-radius: 0.5vw;
    right: -0.1vw;
    font-size: var(--size-18px);
}

/* Icon and Button Spacing */
.candidate-cv-download,
.candidate-cv-upload,
.matchingCandidateBtn {
    margin-left: 6vw !important;
    margin-top: 0.5vw !important;
}

/* Larger Phone Icon */
.megaPhoneIcon {
    width: 6vw !important;
}

/* Sales Note Modal Styling */
.salesNoteMeeting * {
    font-family: var(--font-roobert-regular);
    font-size: var(--size-16px);
    color: var(--color-dark-purple);
}

/* Candidate Profile Picture Modal */
.candidateProfilePicUpload.modal.show .modal-dialog {
    min-width: 30vw !important;
    max-width: 30vw !important;
    width: 30vw !important;
}

.companyCornerRightSideBar {
    height: 10vw;
    overflow: auto;
}

.companyCornerFollowUps .companyCornerRightSideBar {
    padding-top: 0 !important;
}

.companyCornerFollowUps .companyCornerRightSideBar .followUpTitle {
    padding-top: 1vw !important;
    position: sticky;
    top: 0;
    z-index: 999;
    background: var(--budget-bg);
}

.companyCornerTable th {
  width: 33.33%;
}