/* / For the entire scroll bar / */
.leftMenuWrapper::-webkit-scrollbar,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar,
.scrollBarDesign::-webkit-scrollbar,
.p-multiselect-items-wrapper::-webkit-scrollbar {
  width: 0.45vw; /* Width of the scrollbar */
  height: 0.45vw;
}

.leftMenuWrapper::-webkit-scrollbar-track,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar-track,
.scrollBarDesign::-webkit-scrollbar-track,
.p-multiselect-items-wrapper::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 0.5vw;
}

.leftMenuWrapper::-webkit-scrollbar-thumb,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar-thumb,
.scrollBarDesign::-webkit-scrollbar-thumb,
.p-multiselect-items-wrapper::-webkit-scrollbar-thumb {
  background: var(--color-dark-pink);
  border-radius: 0.5vw;
}

.main-menu {
  background: var(--sideBarBgColor);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed !important;
  top: 0;
  transition: width 0.2s linear;
  z-index: 1000;
  overflow: auto;
  padding-bottom: 2vw;
  padding-left: 0.8vw;
  padding-right: 1vw;
  transition: width 1s ease;
  width: 6vw;
}

.leftMenuWrapper {
  height: calc(100vh - 9.5vw) !important;
  overflow: auto;
  padding-right: 1.2vw;
}

.sidebarLogo {
  height: 3.3vw;
}

.sidebar-menu svg {
  width: 1.4vw;
  height: 1.4vw;
  color: var(--action-icon-color);
}

.sidebar-menu.active svg *,
.sidebar-menu:hover svg * {
  color: var(--color-white);
}

.sidebar-menu {
  margin-bottom: 0.5vw !important;
  transition: all 0.5s ease-in-out;
}

.sidebarLogo a {
  padding-left: 0.5vw !important;
}

.sidebar-logo {
  width: 2vw;
  padding-top: 0vw;
}

.sidebar-menu.active,
.sidebar-menu:hover {
  background-color: var(--primaryBrandColor);
  border-radius: 0.7vw;
  box-shadow: 0 0.104vw 0.417vw 0 #f08faf;
}

.sidebar-menu a {
  text-decoration: none !important;
  width: 100%;
  display: block;
  padding: 0.6vw;
}
.main-menu.expanded .sidebar-menu.active .nav-text,
.main-menu.expanded .sidebar-menu:hover .nav-text {
  color: var(--color-white) !important;
}

.main-menu.expanded .nav-text {
  min-height: 1.3vw;
  color: var(--action-icon-color);
  font-size: var(--size-16px);
}

.sidebar-icon {
  background-color: var(--color-white);
  border: 0.052vw solid var(--primaryBrandColor);
  box-shadow: 0 0.104vw 0.417vw 0 #f08faf;
  color: var(--color-dark-pink) !important;
  height: 1.563vw;
  width: 1.563vw;
  transition: all 0.5s ease;
  border-radius: 0.4vw;
}

.sidebar-icon:hover {
  background-color: var(--primaryBrandColor);
  color: var(--color-white) !important;
}

.main-menu.expanded {
  width: 15vw;
}

.expanded-sidebar-logo {
  width: 10vw;
  padding-top: 0vw;
}

.sidebarIcon {
  bottom: 0.75vw;
}

/* / Right sidebar / */
.right-sidebar {
  position: fixed;
  right: 0.677vw;
  bottom: 0;
  width: 18.229vw;
  height: calc(100vh - 6.354vw);
  background-color: var(--color-white);
  box-shadow: rgba(229, 0, 126, 0.516) 0vw 0.052vw 0vw,
    rgba(229, 0, 126, 0.316) 0vw 0vw 0.104vw;
  z-index: 1000;
  overflow-y: auto;
  padding: 1.042vw;
  transition: width 0.3s ease;
  -webkit-transform: translateZ(0) scale(1, 1);
  border-radius: 0vw !important;
}

.close-btn {
  position: absolute;
  top: 0.521vw;
  right: 0.521vw;
  background: none;
  border: none;
  font-size: 0.833vw;
  cursor: pointer;
}

.followUpTimings {
  font-size: var(--size-16px);
  font-family: var(--font-roobert-semibold);
}

.candidateCornerPage .right-sidebar {
  height: calc(100vh - 15.625vw);
}

.rightSidebar {
  margin-right: 0;
  transition: margin-right 0.3s ease;
}

.followUpCard {
  padding: 1vw;
  margin-bottom: 1vw;
}

.icon {
  position: relative; /* Ensure the dot is positioned relative to the icon */
}

.notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust size */
  height: 10px; /* Adjust size */
  background-color: green; /* Dot color */
  border-radius: 50%; /* Make it circular */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); /* Optional for a subtle shadow */
}