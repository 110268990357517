.fileUploadWrapper input[type='file'],
.certificateUpload input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 2.8vw;
  width: 100%;
  cursor: pointer;
}

.fileUploadWrapper {
  cursor: pointer;
  width: fit-content;
}

.fileUploadWrapper .upload-button,
.certificateUpload .btn {
  color: var(--color-dark-pink) !important;
  border: 0.052vw solid var(--color-dark-pink) !important;
  background-color: var(--color-white) !important;
  min-height: 2.344vw;
  padding: 0.5vw 1vw !important;
  font-size: var(--size-16px) !important;
  border-radius: 0.4vw;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileUploadWrapper:hover .upload-button,
.certificateUpload:hover .btn {
  background-color: var(--color-dark-pink) !important;
  color: var(--color-white) !important;
}

.fileUploadWrapper *,
.fileUploadWrapper .form-button * {
  cursor: pointer;
}

.fileUploadWrapper svg {
  width: 1vw !important;
  height: 1vw;
}

.candidateDownload svg,
.certificateUpload svg {
  width: 1.25vw;
  height: 1.25vw;
}
.modal .fileUploadWrapper {
  margin: auto;
}
::-webkit-file-upload-button {
  cursor: pointer;
}

/* ck editor */
.ck-editor {
  box-shadow: 0 0.052vw 0.104vw 0 #f1d8e8, 0 0.052vw 0.156vw 0.052vw #f1d8e8 !important;
  border-radius: 0.6vw;
}

.ck.ck-editor__main>.ck-editor__editable {
  border-bottom-left-radius: 0.6vw !important;
  border-bottom-right-radius: 0.6vw !important;
}

.ck-toolbar {
  border-top-left-radius: 0.6vw !important;
  border-top-right-radius: 0.6vw !important;
}

.ck.ck-editor__main p {
  font-size: var(--size-16px) !important;
  margin-bottom: 0.7vw !important;
  line-height: 1.5vw;
}

.ck-editor__editable_inline,
.custom-ckeditor {
  height: 20vw;
}

.ck.ck-toolbar>.ck-toolbar__items {
  font-size: 0.8vw !important;
}