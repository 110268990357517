.vacancy-step-tabs-absolute {
  top: -0.5vw;
  z-index: 99;
}

.vacancyAddLocation .modal-content {
  width: 50vw;
  margin: auto;
}

.wage-wrapper .input-group-text {
  height: 2.25vw !important;
  right: 1.05vw;
  top: 0.05vw;
  background-color: var(--header-bg-table-color);
  width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
}

.wage-wrapper label,
.wage-wrapper .height-20 {
  display: none;
}

.wage-wrapper {
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}

.vacancyCornerLeftSection {
  background-color: var(--lightGray);
  padding: 1vw;
  border-radius: 0.5vw;
}

.personal-info {
  font-family: var(--font-roobert-semibold);
  font-size: var(--size-16px);
}

.vacancySubTitle {
  color: var(--color-dark-pink);
  font-size: var(--size-20px);
  font-family: var(--font-roobert-semibold);
}

.vacancyCornerLeftSection .vacancyContactPerson {
  font-size: var(--size-18px);
}

.form-height-vacancy-overview {
  min-height: calc(100vh - 13vw);
}



/* Manage vacancies */

.manageVacancies th:first-child {
  width: 7vw !important;
  max-width: 7vw !important;
}

.manageVacancies th:nth-child(2),
.manageVacancies th:nth-child(4) {
  width: 14%;
}

.manageVacancies th:nth-child(3) {
  width: 12vw;
  max-width: 12vw;
}

.manageVacancies th:nth-child(5) {
  width: 8%;
}

.manageVacancies th:nth-child(6) {
  width: 8%;
}

.manageVacancies th:nth-child(7),
.manageVacancies th:nth-child(8) {
  width: 10%;
}

.matchingListPopup.modal.show .modal-dialog {
  min-width: 90vw !important;
  max-width: 90vw !important;
}

.matchingListIframeHeight {
  min-height: calc(100vh - 20vw) !important;
  height: calc(100vh - 20vw) !important;
  max-height: calc(100vh - 20vw) !important;
}

.vacancyDownload svg {
  width: 3vw;
  height: 3vw;
  color: var(--color-dark-pink) !important;
  padding: 0.4vw;
  transition: all 0.3s ease;
}