.company-tabs button {
  margin-right: 1vw;
}

.company-tabs .link.active,
.company-tabs .link:hover,
.location-tabs .link.active,
.location-tabs .link:hover,
.vacancy-tabs .link.active,
.vacancy-tabs .link:hover {
  color: var(--color-dark-pink);
  text-decoration: underline !important;
  text-underline-offset: 0.3vw;
  text-decoration-thickness: 0.2vw;
}

.company-tabs .link,
.location-tabs .link,
.vacancy-tabs .link {
  font-family: var(--font-roobert-semibold);
}

.company-step-tabs-absolute {
  top: -0.5vw;
  z-index: 99;
}

.fieldPrefix {
  top: 1.711vw;
  width: 1.8vw;
  height: 2.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--header-bg-table-color);
  left: -0.1vw;
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
  font-size: var(--size-16px);
}

.generalTabVAT input {
  padding-left: 2vw !important;
  padding-right: 5.5vw !important;
}

.companyVatVerify {
  position: absolute;
  top: 1.7vw;
}

/* Manage company */

.manageCompanies th:nth-child(1) {
  max-width: 7vw;
  width: 7vw;
}

.manageCompanies th:nth-child(2) {
  width: 13%;
}

.manageCompanies th:nth-child(3) {
  width: 8%;
}

.manageCompanies th:nth-child(4) {
  width: 12%;
}

.manageCompanies th:nth-child(5) {
  width: 10%;
}

.manageCompanies th:nth-child(6) {
  width: 18%;
}

.manageCompanies th:nth-child(7) {
  width: 10%;
}

.manageCompanies th:nth-child(8) {
  width: 7%;
}

.selectSection.multiSelectDropdown__menu {
  width: fit-content !important;
}


.manageCompanyContacts th:first-child {
  width: 6vw !important;
  max-width: 6vw !important;
}

.manageCompanyContacts th:nth-child(2),
.manageCompanyContacts th:nth-child(6) {
  width: 10%;
}

.manageCompanyContacts th:nth-child(3),
.manageCompanyContacts th:nth-child(4),
.manageCompanyContacts th:nth-child(7) {
  width: 8%;
}


.manageCompanyContacts th:nth-child(5) {
  width: 10%;
}

.manageCompanyContacts th:nth-child(8){
  width: 8%;
}

.manageCompanyContacts th:nth-child(9){
  width: 6%;
}

.manageCompanyContacts th:nth-child(10){
  width: 7%;
}

.manageCompanyContacts th:nth-child(11){
  width: 5%;
}

.companyRightSideBar {
  height: calc(100vh - 9vw) !important;
  top: 7vw !important;
}

.companyDocumentsThead {
  z-index: 999;
  background-color: var(--color-white);
}

.formManageBtnHover {
  background-color: var();
}

.formManageBtnHover {
  background-color: var(--budget-bg) !important;
  border: 0.052vw solid var(--circlePrimaryColor) !important;
  box-shadow: 0 0.104vw 0.104vw 0 #63636333;
  color: var(--color-dark-pink) !important;
  font-size: var(--size-18px);
  flex-basis: 0;
  flex-grow: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw !important;
  position: relative;
  top: -0.1vw;
}

.formManageBtnHover:hover {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
}


#memoryTooltip .tooltip-inner {
  min-width: 15vw !important;
}

#memoryTooltip .tooltip-inner .memoryTooltipData {
  text-align: left;
  color: var(--color-dark-purple) !important;
  font-family: var(--font-roobert-regular);
  font-size: var(--size-18px);
  white-space: break-spaces;
}

#memoryTooltip.tooltip .tooltip-arrow {
  width: 1vw !important;
  height: 1vw !important;
  top: -1.1vw !important;
}

#memoryTooltip.tooltip {
  --bs-tooltip-bg: var(--color-dark-pink);
  --bs-tooltip-max-width: 3vw;
  --bs-tooltip-arrow-width: 0.8vw !important;
  --bs-tooltip-arrow-height: 0.5vw !important;
}

.reminderPopup .calender-icon .input-group-text {
  padding: 0.25vw !important;
}

.addressPopup {
  --bs-modal-zindex:1500 !important;
}

.addressPopup .modal-footer {
  display: none !important;
}

.addressPopup .modal-body {
  max-height: calc(100vh - 10vw);
  overflow: auto;
}

.addressPopupBody {
  padding-right: 1.5vw !important;
}

.addressPopup .modal-header {
  padding: 0.5vw 0 !important;
}